import React from "react";
import DownArrows from "components/down-arrows";
import Nav from "components/nav";
import Logo from "assets/SHEBASH_Wordmark_GN.png";
import "./Home.scss";


export default function Home(): JSX.Element {
  return (
    <div className="home-page">
      <Nav/>
      <div className="home-preview">
        <div className="logo-block">
          <img src={Logo} alt="Logo" title="Logo"/>
        </div>
        <DownArrows/>
      </div>
      <div className="home-content"/>
      <div className="home-sub-content"/>
      <div className="home-sub-content"/>
    </div>
  );
}
