import React, { useEffect } from "react";
import "./nav.scss";
import Logo from "assets/SHEBASH_Inline_WH.png";

export default function Nav(): JSX.Element {
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 33) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <header className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="logo">
        <img src={Logo} alt="Logo" title="Logo"/>
      </div>

      <nav className="navigation">
        <ul>
          <li><a href="#post1">Home</a></li>
          <li><a href="#post2">Home</a></li>
          <li><a href="#post3">Home</a></li>
          <li><a href="#post4">Home</a></li>
        </ul>
        <ul>
          <li><a href="#post1">Home</a></li>
          <li><a href="#post2">Home</a></li>
          <li><a href="#post3">Home</a></li>
          <li><a href="#post4">Home</a></li>
        </ul>
      </nav>

    </header>
  );
};



