import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./scenes/Home";


import "./App.scss";

export default function App(): JSX.Element {
  return (
    <div className="kd-chat-container kd-wrap">
      <div className="data-container">
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Home}/>
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}
